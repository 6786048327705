import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./TeacherTestView.module.css";
const TeacherTestView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { testId } = location.state;
  // console.log(testId, "testId");
  return (
    <div className={styles.outerContainer}>
      <div className={styles.headingContainer}>
        <div style={{ display: "flex" }}>
          <p className={styles.heading}>{"Test > Section Test"}</p>
          {/* <p className={styles.heading}>{testId}</p> */}
        </div>
        <div>
          <p className={styles.heading}>
            <span style={{ color: "green" }}>Published</span> || Total: 52 marks
          </p>
        </div>
        <div className={styles.buttonContainer1}>
          <button className={styles.activeButton} disabled>
            View Test
          </button>
          <button
            className={styles.inactiveButton}
            onClick={() => {
              // console.log("View Submissions");
              navigate("/teacher-submission-view", { state: { testId } });
            }}
          >
            View Submissions
          </button>
        </div>
      </div>
      {/* Button To Be Visible Only When Screen Width is Less Than 768px */}
      <div className={styles.buttonContainer2}>
        <button className={styles.activeButton} disabled>
          View Test
        </button>
        <button
          className={styles.inactiveButton}
          onClick={() => {
            // console.log("View Submissions");
            navigate("/teacher-submission-view");
          }}
        >
          View Submissions
        </button>
      </div>
      <div className={styles.detailcontainer}>
        <div style={{ display: "flex" }}>
          <p className={styles.heading2}>{`ID: ${testId}`}</p>
        </div>
        <div>
          <button onClick={() => {
                navigate("/AddTest")}}>View Test</button>
          <button>View Submissions</button>
        </div>
      </div>
    </div>
  );
};
export default TeacherTestView;
