import React, { useEffect, useState } from "react";
import { fetchSubmissionDetails } from "../../../Store/API/User";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";
import PreviewModal from "../../Modals/PreviewModal";
import classes from "./Assighnment.module.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
const AssignmentSubmit = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const location = useLocation();
  const currentAssignmentId = location.state.assignmentId;
  const userState = useSelector((state) => state.userData.userData);
  const token = "98c1ec61f46858ef5bcce7f85f5286cbtutMjk0Mg==";
  const studentId = userState?.userId;
  const assignmentId = currentAssignmentId;

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchSubmissionDetails(
          token,
          studentId,
          assignmentId,
          dispatch
        );
        console.log(result);
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const handleOpenModal = (submission) => {
    if (isOpenModal) {
      setSelectedSubmission(null);
      setIsOpenModal(false);
    } else {
      setSelectedSubmission(submission);
      setIsOpenModal(true);
    }
  };

  const content = selectedSubmission && (
    <div className={classes.submissioncontent}>
      <div className="text-2xl font-bold pl-32 ">View Submission</div>
      <div
        className="text-base mt-3 ml-4 font-medium"
        style={{ color: "#4C4C4C" }}
      >
        Remark
      </div>
      <div className={classes.textField}>
        {selectedSubmission.submission.comment}
      </div>
      <div
        className="text-base mt-2 ml-4 font-medium"
        style={{ color: "#4C4C4C" }}
      >
        Attachments
      </div>
      <div className="flex ml-4">
        {selectedSubmission.submission.attachments.map((attachment, index) => (
          <div className={classes.attachmentbox} key={index}>
            <a href={attachment.url} target="_blank" rel="noopener noreferrer">
              {attachment.name}
            </a>
          </div>
        ))}
      </div>
      {selectedSubmission.submission.solution && (
        <div>
          <div
            className="text-base mt-3 ml-4 font-medium"
            style={{ color: "#4C4C4C" }}
          >
            Solution
          </div>
          <div className={classes.attachmentbox}>
            <a
              href={selectedSubmission.submission.solution.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedSubmission.submission.solution.name}
            </a>
          </div>
        </div>
      )}
      <div className="mt-2 ml-4 text-sm font-Poppins font-normal">
        {selectedSubmission.submission.solution ? (
          selectedSubmission.submission.solution.length !== 0 ? (
            <div style={{ color: "#61C454" }}>
              <EditNoteIcon /> Evaluated
            </div>
          ) : (
            <div style={{ color: "#FF9330" }}>
              <EditNoteIcon /> Not Evaluated
            </div>
          )
        ) : (
          <div style={{ color: "#FF9330" }}>
            <EditNoteIcon /> Not Evaluated
          </div>
        )}
      </div>
    </div>
  );
  // const options = {
  //   "due-date": ["Today", "Tomorrow", "Next week"],
  //   attachments: ["Assignment 1", "Assignment 2", "Assignment 3"],
  //   actions: ["Edit", "Delete", "View details"],
  // };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-[95vh] w-[100%] overflow-auto">
      <div className="batch-assignments dashboard-content overflow-auto flex md:w-[100%] w-full flex-col px-3 py-10 h-full bg-gray-100 mt-3 ">
        <div className={classes.formRow} style={{ marginLeft: "0px" }}>
          <div className="form-buttons">
            <button
              type="submit"
              style={{
                borderRadius: "5px",
                backgroundColor: "#FFA63D",
                borderColor: "#FFF",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#fff",
                margin: "0 5px",
                width: "200px",
                height: "36px",
                textAlign: "center",
                padding: "5px",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
              onClick={(event) => {
                event.preventDefault();
                navigate("/assignment/details", {
                  state: { assignmentId: currentAssignmentId },
                });
              }}
            >
              Assignment Details
            </button>
            &nbsp;
            <button
              type="submit"
              style={{
                borderRadius: "5px",
                backgroundColor: "#FF3D5D",
                borderColor: "#FFF",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#fff",
                margin: "0 5px",
                width: "200px",
                height: "36px",
                textAlign: "center",
                padding: "5px",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              Submissions
            </button>
            &nbsp;
          </div>
        </div>
        <div
          className="w-[700px] sm:w-full flex flex-col bg-white rounded-lg border-t-2 border-t-orange-500"
          style={{ padding: "10px 20px" }}
        >
          <div className={classes.submmissionheading}>
            <div className="">Submission Date-Time</div>
            <div className="">Attachments</div>
            <div className="">Actions</div>
          </div>
          <div className="">
            {data && data.submission_details.length > 0 ? (
              data.submission_details.map((submission, index) => (
                <div
                  key={index}
                  className={`flex justify-between h-10 bg-gray-300 mt-6 `}
                  style={{ borderRadius: "5px" }}
                >
                  <div className={classes.subdetails}>
                    {submission.submission.submit_date} |
                    {submission.submission.submit_time}
                  </div>
                  <div className={classes.subdetails}>
                    <p>{submission.submission.attachments.length}</p>
                  </div>
                  <div className={classes.subdetails}>
                    <p>
                      <button onClick={() => handleOpenModal(submission)}>
                        View <RemoveRedEyeIcon />
                      </button>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-xl text-[#F3A041]">
                  No submissions available.
                </p>
              </div>
            )}
            {isOpenModal && (
              <PreviewModal content={content} onClose={handleOpenModal} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentSubmit;
