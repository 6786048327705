// import { useDispatch } from "react-redux";
import { getQuizQuestion } from "../API/API";
import { answerActions } from "./Slices/AnswerSlice";
import { questionActions } from "./Slices/QuestionSlice";

export const initializeAnswerArray = (token, quizID) => {
  return async (dispatch) => {
    const tempArray = [];
    const questions = await getQuizQuestion(token, quizID);
    if (questions?.length > 0) {
      questions?.forEach((element) => {
        tempArray.push({
          questionId: element.question_id,
          position: element.question_order,
          subject: element.section_subject,
          section: element.section,
          answer: "",
          question_state: "Not Visited",
          attachment: "",
          attempt_time: 0,
        });
      });
    }
    dispatch(answerActions.setAnswer(tempArray));
    dispatch(questionActions.setQuestions(questions));
  };
};

export const updateMendatory = (mendatorDetails) => {
  return async (dispatch) => {
    // console.log(mendatorDetails, "mendatory");
    dispatch(answerActions.updateMendatory(mendatorDetails));
  };
};


