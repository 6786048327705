import React, { useState, useEffect } from "react";
import "./Course.css";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getMyCourses } from "../../../Store/API/API";
import { useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";
import classes from "./../Batch/Batch.module.css";
const Courses = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [myCourses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [institute, setInstitute] = useState("Innovartan");
  const [courseType, setCourseType] = useState("Online Course");
  const dispstch = useDispatch();
  const navigate = useNavigate();

  const fetchCourses = async () => {
    const data = await getMyCourses(
      userState.userId,
      userState.token,
      dispstch
    );
    if (data) {
      setCourses(data.shop);
    }
    setIsLoading(false);
  };

  const handleInstituteChange = (event) => {
    setInstitute(event.target.value);
  };

  const handleCourseTypeChange = (event) => {
    setCourseType(event.target.value);
  };

  useEffect(() => {
    fetchCourses();

    // eslint-disable-next-line
  }, [userState]);

  const SectionCard = ({ cardType, data, isVedio }) => {
    return (
      <div
        className="sectionCard"
        onClick={() => {
          navigate("/course-details", { state: { course_id: data.course_id } });
        }}
      >
        <img
          src={data?.cover_photo_url || data?.thumb_nail}
          className="cardImage"
          alt="course"
        ></img>
        <div className="contentContainer">
          <p className="cardHeading">{data?.name}</p>
          {!isVedio && (
            <p className="cardHeading">
              {data?.curriculum || data?.batch_name} |{" "}
              {data?.class || `${data?.fromTime} - ${data?.toTime}`} |{" "}
              {data?.subject || data?.date}
            </p>
          )}
          {<p className="cardHeading">{data?.title}</p>}
          <p className="cardSubHeading">By: Innovartan</p>
        </div>
      </div>
    );
  };
  const Section = ({ heading, cardType, test, data }) => {
    return (
      <div className="section">
        <div className="sectionOuter">
          <div>
            <i className="fa-solid fa-book-open"></i>
            <span style={{ marginLeft: "10px" }}>{heading}</span>
          </div>
        </div>
        <div className="horizontalLine"></div>
        <div className={classes.batchContainer}>
          <div className={classes.dropdownContainer}>
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ maxWidth: "100%" }}
              >
                <InputLabel>Select Course Type</InputLabel>
                <Select
                  value={courseType}
                  onChange={handleCourseTypeChange}
                  label="Select Institute"
                  style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                  }}
                >
                  <MenuItem value={"Video Course"}>Video Course</MenuItem>
                  <MenuItem value={"Online Course"}>Online Course</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.dropdownContainer}>
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ maxWidth: "100%" }}
              >
                <InputLabel>Select Institute</InputLabel>
                <Select
                  value={institute}
                  onChange={handleInstituteChange}
                  label="Select Institute"
                  style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                  }}
                >
                  <MenuItem value={"Aviral"}>Aviral</MenuItem>
                  <MenuItem value={"Edvanz Institute"}>
                    Edvanz Institute
                  </MenuItem>
                  <MenuItem value={"Innovartan"}>Innovartan</MenuItem>
                  <MenuItem value={"NEP Institute"}>NEP Institute</MenuItem>
                  <MenuItem value={"SRGS Institute"}>SRGS Institute</MenuItem>
                  <MenuItem value={"Inst Career"}>Inst Career</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {userState.userType === "institute" && (
            <div
              className="createCourseButton"
              onClick={() => {
                navigate("/AddCourse");
              }}
            >
              <p style={{ margin: "0" }}>Create Course</p>
            </div>
          )}
        </div>
        <div className="horizontalLine"></div>
        <div className="sectionCardContainer">
          {data?.map((test, index) => (
            <SectionCard cardType={cardType} data={test} key={index} />
          ))}
        </div>
      </div>
    );
  };
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="courseDashboard">
      {myCourses?.length > 0 ? (
        <Section
          heading={"My Courses"}
          cardType={"normal"}
          test={false}
          data={myCourses}
        />
      ) : null}

      {myCourses?.length === 0 && (
        <div className="noData">
          <h5>No Data Found</h5>
        </div>
      )}
    </div>
  );
};
export default Courses;
