import React, { useState, useEffect } from "react";
import "./Course.css";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import "../TestDetails/AddTest/custom-select.css";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetails } from "../../../Store/API/API";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Spinner from "../../UserInterface/Spinner/Spinner";
import EastIcon from '@mui/icons-material/East';
const CourseDetail = () => {
  const animatedComponents = makeAnimated();
  const userState = useSelector((state) => state.userData.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [courseDetails, setCourse] = useState([]);
  const [selected, setSelected] = useState("One Time");
  const [cartview, setcartview] = useState(false);
  const [emivalue, setemivalue] = useState("")
  const [discount, setdiscount] = useState("30")
  const [fianlAmount, setfianlAmount] = useState("")
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emiOptions = [
    { value: '4', label: '4 months' },
    { value: '6', label: '6 months' },
    { value: '12', label: '12 months' },
  ];
  const { course_id } = location.state;
  const fetchCourse = async () => {
    const data = await getCourseDetails(course_id, userState.userId, dispatch);
    if (data) {
      setCourse(data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchCourse();
  }, [userState]);
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const BillingSummary = () => {
    return (
      <div className="billDetails">
        <div className="heading mt-4">Billing Summary</div>
        {(() => {
          const isEMI = emivalue;
          const price = isEMI ? Math.ceil(courseDetails.content_amount / emivalue) : Math.ceil(courseDetails.content_amount);
          const gst = Math.round(price * 0.18);
          const totalAmount = (price + gst) - discount;
          setfianlAmount(totalAmount)
          return (
            <>
              <div className="priceDetails">
                <div className="flex justify-between mt-2 font-poppins text-lg font-normal text-[#4A4A4A]">
                  <span>{isEMI ? 'Installment' : 'Price'}</span>
                  <span>₹ {price}</span>
                </div>
                <div className="flex justify-between mt-2 font-poppins text-lg font-normal text-[#4A4A4A]">
                  <span>GST (18%)</span>
                  <span>₹ {gst}</span>
                </div>
                <div className="flex justify-between mt-2 mb-4 font-poppins text-lg font-normal text-[#4A4A4A]">
                  <span>Discount</span>
                  <span>₹ {discount !== 0 ? discount : "0"}</span>
                </div>
              </div>
              <div className="flex justify-between mt-2 font-poppins text-lg font-normal text-[#4A4A4A]">
                <span>Amount To Be Paid</span>
                <span>₹ {totalAmount}</span>
              </div>
            </>
          );
        })()}
      </div>
    );
  };
  return (
    <div className="course-detail-container">
      <div className="mainContainer">
        <div className="course-content  ">
          <div className="course-header">
            <div className="course-image-placeholder">
              <img
                src={courseDetails.cover_photo_url}
                className="cardImage"
              ></img>
            </div>
            <div className="course-info">
              <h1>
                <strong>{courseDetails.name}</strong>
              </h1>
              <ul>
                <li className="subDetail">
                  {courseDetails?.subject}
                  {" | "} {courseDetails?.class} {" | "}
                  {courseDetails?.curriculum}
                </li>
                <li>{courseDetails?.sub_title}</li>
              </ul>
            </div>
          </div>
          <div className="course-meta">
            <div className="flex ">
              <div className="course-meta-icon"><img src="/language.png" /></div>
              <span>
                Language: <strong>English</strong>
              </span>
            </div>
            <div className="flex">
              <div className="course-meta-icon"><img src="/clock.png" /></div>
              <span>
                Total Validity:{" "}
                <strong>{courseDetails.validity_days} days </strong>
              </span>
            </div>
            <div className="flex">
              <div className="course-meta-icon"><img src="/stairs.png" /></div>
              <span>
                Level: <strong>Beginner</strong>
              </span>
            </div>
          </div>
          <div className="course-actions">
            <div className="cupon-code">
              <input type="text" placeholder="Have a discount code?"></input>
              <Button variant="contained" className="btn"><EastIcon /></Button>
            </div>
            <span className="course-price">
              ₹ {courseDetails.content_amount}
            </span>
          </div>
          <div className="course-learn">
            <h2>
              <strong>What you'll learn</strong>
            </h2>
            <div dangerouslySetInnerHTML={{ __html: courseDetails.topic }}></div>
          </div>
          <div className="course-learn">
            <h2>
              <strong>Requirements</strong>
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: courseDetails.requirement }}
            ></div>
          </div>
          <div className="course-learn">
            <h2>
              <strong>Description</strong>
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: courseDetails.description }}
            ></div>
          </div>
        </div>
        <div className="course-sidebar overflow-x-hidden">
          {!cartview ? (
            <>
              {courseDetails.can_buy === 1 ? (
                <button className="buy-now" onClick={() => { setcartview(true) }}>Buy Now</button>
              ) : (
                <button
                  className="buy-now"
                  onClick={() => {
                    navigate("/course-content", {
                      state: { course_name: courseDetails.course_name },
                    });
                  }}
                >
                  View Details
                </button>
              )}
              <div className="course-learn">
                <h2>
                  <strong>This course includes </strong>
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: courseDetails.include }}
                ></div>
              </div>
            </>
          ) : (
            <div className="">
              <div className="heading mb-2">Select a Payment Method</div>
              <div className="payment-method">
                <div className=" flex gap-8 mb-4">
                  <span className={`${selected === "One Time" ? "bg-[#C7CCFF] text-[#000e8f]" : ""}`}
                    onClick={() => {
                      setSelected("One Time")
                      setemivalue("")
                    }
                    }>One Time</span>
                  <span className={`${selected === "EMI" ? "bg-[#C7CCFF] text-[#000e8f]" : ""}`} onClick={() => setSelected("EMI")} >EMI</span>
                </div>
              </div>
              {selected === "One Time" ? (<BillingSummary />

              ) : (<>
                <div className="heading mb-2">Choose EMI Options</div>
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={emiOptions}
                  placeholder={'Choose EMI'}
                  required="Enter Emi options"
                  classNamePrefix="custom-select"
                  onChange={(selectedOptions) => {
                    setemivalue(selectedOptions.value)
                  }}
                />
                {emivalue ? (
                  <div className="mt-4">
                    <div className="flex justify-between font-poppins font-semibold text-lg text-[#0C1E33]">
                      EMI Plans: <span className="font-normal">{Math.ceil(courseDetails.content_amount / emivalue)} &#10006; {emivalue} Months</span>
                    </div>
                    <BillingSummary />
                  </div>
                ) : (
                  ""
                )}
              </>)}
              {(emivalue || (selected==='One Time'))?
              (<button  className="buy-now  mt-4"  onClick={() => { ""}}>Pay ₹ {fianlAmount} </button>):("")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CourseDetail;
