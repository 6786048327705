import React, { useState } from 'react';
import classes from './AddCourse.module.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import makeAnimated from 'react-select/animated';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import "../TestDetails/AddTest/custom-select.css";
import Editor from '../../Editor/Editor';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Form from 'react-bootstrap/Form';

const AddCourse = () => {
  const animatedComponents = makeAnimated();
  const [classType, setClassType] = useState('free');
  const navigate = useNavigate();
  const steps = ['Basic Details', 'Description', 'Pricing'];
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleFinish = () => {
    alert("Course Added Successfully");
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [checkedItems, setCheckedItems] = useState({
    Promote: false,
    Show: false,
    EMI: false,
  });

  const curriculumOptions = [
    { value: 'JEE', label: 'JEE' },
    { value: 'CBSE', label: 'CBSE' },
  ];

  const subjectOptions = [
    { value: 'Physics', label: 'Physics' },
    { value: 'Chemistry', label: 'Chemistry' },
    { value: 'Maths', label: 'Maths' },
    { value: 'Biology', label: 'Biology' },
  ];

  const classesOptions = [
    { value: '9th', label: 'IX' },
    { value: '10th', label: 'X' },
    { value: '11th', label: 'XI' },
    { value: '12th', label: 'XII' },
  ];

  const handelCancel = () => {
    navigate("/courses")
  }

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.container}>
        <div className={classes.steper}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} orientation="horizontal" >
              {steps.map((label, index) => (
                <Step key={index} >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      </div>
      <div className={classes.rows}>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          {activeStep === 0 && (
            <>
              <div className={`${classes.inputTitel}`}>Course Name</div>
              <input type="text" required className={classes.titel} placeholder='Example: Photography Masterclass: A Complete Guide to Photography' onChange={(e) => {
                console.log(e.target.value);
              }} name="title" />
              <div className={`${classes.inputTitel}`}>Course Subtitle</div>
              <input type="text" required className={classes.titel} placeholder='Example: The Best Online Professional Photography Class: How to Take Amazing Photos for Beginners & Advanced Photographers' onChange={(e) => {
                console.log(e.target.value);
              }} name="subtitle" />
              <div className={classes.columns}>
                <div className={classes.col1}>
                  <div className={`${classes.inputTitel}`}>Subject</div>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={subjectOptions}
                    placeholder={'Select Subject'}
                    required="Enter test Type"
                    classNamePrefix="custom-select"
                    onChange={(selectedOptions) => {
                      ""
                    }}
                  />

                  <div className={`${classes.inputTitel}`}>Curriculum</div>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={curriculumOptions}
                    placeholder={'Select Curriculum'}
                    required="Enter test Type"
                    classNamePrefix="custom-select"
                    onChange={(selectedOptions) => {
                      ""
                    }}
                  />
                </div>
                <div className={classes.col2}>
                  <div className={`${classes.inputTitel}`}>Classes</div>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={classesOptions}
                    placeholder={'Select Class'}
                    required="Enter test Type"
                    classNamePrefix="custom-select"
                    onChange={(selectedOptions) => {
                      ""
                    }}
                  />
                  <div className={`${classes.inputTitel}`}>Cover Photo</div>
                  <Form.Group controlId="formFileLg">
                    <Form.Control
                      type="file"
                      style={{
                        backgroundColor: "#F3F1FF",
                        height: "40px",
                        padding: "0",
                        lineHeight: "40px",
                        borderRadius: "5px",
                        // overflow: "hidden", // Hides any overflow from file input
                        paddingLeft: "10px",
                        borderColor: "#717171", 
                      }}
                    />
                  </Form.Group>


                </div>
              </div>
            </>
          )}
          {activeStep === 1 && (
            <>
              <div className={`${classes.inputTitel}`}>Description</div>
              <div className={classes.textArea}>
                <Editor
                  key={1}
                  height={"7rem"}
                  width={"100%"}
                  placeholder="write your content ...."
                  style={{ border: "none" }}
                />
              </div>
              <div className={`${classes.inputTitel}`}>Requirements</div>
              <div className={classes.textArea}>
                <Editor
                  key={1}
                  height={"7rem"}
                  width={"100%"}
                  placeholder="write your content ...."
                  handleChange={() => { console.log("hii") }
                  }
                />
              </div>
              <div className={`${classes.inputTitel}`}>What will you learn</div>
              <div className={classes.textArea}>
                <Editor
                  key={3}
                  height={"7rem"}
                  width={"100%"}
                  placeholder="write your content ...."
                />
              </div>
            </>
          )}
          {activeStep === 2 && (
            <>
              <div className={`${classes.inputTitel}`}>Includes</div>
              <div className={classes.textArea}>
                <Editor
                  key={4}
                  height={"7rem"}
                  width={"100%"}
                  placeholder="write your content ...."
                />
              </div>
              <div className={`${classes.inputTitel}`}>Course Validity</div>
              <input type='text' className={classes.textArea} style={{ height: '2rem', borderRadius: '10px', paddingLeft: '5px' }}></input>
              <div className={`${classes.inputTitel}`}>Course Type</div>
              <div>
                <FormControlLabel
                  control={
                    <Radio
                      checked={classType === 'free'}
                      onChange={(event) => {
                        setClassType(event.target.value);
                      }}
                      value="free"
                      name="classType"
                      className={classes.radiobtn}
                    />
                  }
                  label="Free"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={classType === 'paid'}
                      onChange={(event) => {
                        setClassType(event.target.value);
                      }}
                      value="paid"
                      name="classType"
                      className={classes.radiobtn}
                    />
                  }
                  label="Paid"
                />
              </div>
              <div className={`${classes.inputTitel}`}>Choose what to show</div>
              <div className="flex flex-col">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.checkbox1}
                      onChange={handleCheckboxChange}
                      name="Promote"
                      className="custom-checkbox"
                    />
                  }
                  label="Promote on Online Courses"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.checkbox2}
                      onChange={handleCheckboxChange}
                      name="Show"
                      className="custom-checkbox"
                    />
                  }
                  label="Show on Dashboard"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.checkbox3}
                      onChange={handleCheckboxChange}
                      name="EMI"
                      className="custom-checkbox"
                    />
                  }
                  label="Enable installments"
                />
              </div>
            </>
          )}
        </form>
        <span className={classes.buttonContainer}>
          <div className={classes.btns}>
            <div style={{ gap: "20px", display: "flex" }} >
              <Button variant="contained" onClick={handelCancel}>
                Cancel
              </Button>
              {activeStep >= 1 && <Button onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }} variant="contained" >
                Back
              </Button>}
            </div>
            <Button onClick={activeStep === steps.length - 1 ? handleFinish : handleNext} variant="contained" >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>
        </span>
      </div>
    </div>
  );
};
export default AddCourse;