import React from "react";
import { FaRegFileImage } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const AttachmentBox = ({ attachment, isImage, isWhite }) => {
  const handleFileClick = () => {
    window.open(attachment.url, "_blank");
  };
  return (
    <div
      className={`p-2 ${
        isWhite ? "bg-white" : "bg-gray-100"
      } rounded-lg flex w-[12rem] h-[4rem] justify-start items-center`}
    >
      {isImage ? (
        <Zoom>
          <img
            src={attachment ? attachment.url : ""}
            alt="Attachment"
            className="w-full h-auto cursor-pointer object-contain"
          />
        </Zoom>
      ) : (
        <FaFilePdf
          size={40}
          className="text-blue-800 mx-auto cursor-pointer "
          onClick={handleFileClick}
        />
      )}
      <div className="text-black decoration-white truncate text-base hover:text-blue-600 mt-2  w-full px-2 ">
        {attachment.name}
      </div>
    </div>
  );
};

export default AttachmentBox;
